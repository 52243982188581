import {Component, Prop, Watch} from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';
import {namespace} from 'vuex-class';
import Common from '@/services/Common';

const category = namespace('category');

@Component({
    name: 'ModalListeTomes',
})
export default class ModalListeTomes extends BaseScript {
    @Prop(Object) private readonly tomes!: any;

    @category.Getter('getCollectionData') private readonly getCollectionData!: any;

    private commonFunc: any = Common;

    constructor() {
        super();
    }
}
