import {Component, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {formIconsList} from '@/assets/img/formIcons';
import Common from '@/services/Common';
import {BaseScript} from '@/components/BaseScript';
// @ts-ignore
import PercentageCircle from '@/components/Common/PercentageCircle/PercentageCircle.vue';
import ImageViewer from '@/components/Common/ImageViewer/ImageViewer.vue';
import StatutEtatBlock from '@/components/Category/Item/StatutEtatBlock.vue';
import {ItemFieldsConfig} from '@/config/ItemFieldsConfig';
import {config} from '@/config/CategoryConfig';
import {categoryTypes} from '@/config/CategoryType';
import ModalListeTomes from '@/components/Category/Item/ModalListeTomes/ModalListeTomes.vue';

// modules
const general = namespace('general');
const category = namespace('category');

// @ts-ignore
@Component({
    name: 'Item',
    components: {
        PercentageCircle,
        ImageViewer,
        StatutEtatBlock,
        ModalListeTomes,
    },
})
export default class ItemBaseScript extends BaseScript {
    protected data: any = {}; // données de l'item
    protected modalData: any = {}; // les données envoyées à la modal lors de l'édition d'un item
    protected iconsList: any; // liste des icônes
    protected commonFunc: any = Common; // fonctions communes
    protected showReleaseDate: any = ''; // variable utilisée pour afficher la date de sortie
    protected showEndDate: any = ''; // variable utilisée pour afficher la date de fin
    protected selectedImage: string = ''; // permet de stocker l'image pour le visualisateur d'image
    protected isVisitor: boolean = Common.getIsVisitor(); // indique si l'utilisateur est un visiteur
    protected itemFields: any = ItemFieldsConfig.fields; // Liste des champs à afficher
    protected userUrl: string = ''; // utilisé pour les lien changelog
    protected configCategory: any = config;
    protected categoryTypes: any = categoryTypes;

    // props
    @Prop(Object) protected readonly getData!: any;

    // actions
    @general.Action('setModalData') protected setModalData!: any;
    @general.Action('addChangeLog') protected readonly addChangeLog!: any;
    @general.Action('setLoadingStatus') protected readonly setLoadingStatus!: any;
    @general.Action('deleteSelectedImg') protected readonly deleteSelectedImg!: any;
    @general.Action('setToastData') protected readonly setToastData!: any;
    @category.Action('deleteItem') protected readonly deleteItemFunc!: any;
    @category.Action('filterCheck') protected readonly filterCheck!: any;
    @category.Action('setItemsList') protected readonly setItemsList!: any;
    @category.Action('setOwnItemsFullList') protected readonly setOwnItemsFullList!: any;
    @category.Action('setItemsFullList') protected readonly setItemsFullList!: any;

    // getters
    @category.Getter('getCollectionData') protected readonly getCollectionData!: any;
    @category.Getter('getSearchData') protected readonly getSearchData!: any;

    constructor() {
        super();
        this.iconsList = formIconsList();

        // si on est visiteur, on récupére 'user' pour le lien des changelogs
        if (this.isVisitor) {
            this.userUrl = 'user';
        }
    }

    /**
     * @protected
     * @return {void}
     */
    protected mounted(): void {
        this.$bvToast.show('toast-' + this.data._id);

        if (!Common.checkIsNotUndifined(this.getCollectionData)) {
            return;
        }

        // initialisation des données de l'item
        this.data = new this.getCollectionData.className(this.getData);

        this.checkIfDate();

        // préparation des données pour la modale
        this.modalData = {
            title: 'Editer ' + this.getCollectionData.phrases.leSingulier + ' : ' + this.data.title,
            formName: 'ItemForm',
            formIconName: 'edit',
            docData: this.data,
        };
    }

    /**
     * Permet de supprimer un item
     *
     * @async
     * @protected
     * @return {Promise<void>}
     */
    protected async deleteItem(): Promise<void> {
        // on vérifie si la personne est sûr de vouloir supprimer l'item
        if (confirm('Veux-tu vraiment supprimer la fiche ' + this.getCollectionData.phrases.deSingulier + ' "' + this.data.title + '" ?')) {
            // on vérifie si on peut supprimer l'image (si il n'est pas dans une autre fiche) et on la supprime si possible
            await this.deleteSelectedImg(this.data.image);

            // on active la barre de chargement
            this.setLoadingStatus('true');

            // on supprime l'item
            await this.deleteItemFunc(this.data._id).then(async () => {
                // Si c'est bien supprimer on ajoute une nouvelle ligne dans les logs
                await this.addChangeLog({
                    uid: this.data.uid,
                    title: this.data.title,
                    category: this.getCollectionData.category,
                    type: this.getCollectionData.type,
                    date: Date.now(),
                    statut: 'delete',
                });

                // on affiche le toast indiquant à l'utilisateur que l'item est bien supprimé.
                this.setToastData({
                    show: true,
                    title: 'Suppression',
                    msg: 'La fiche ' + this.data.title + ' a bien été supprimée !',
                });

                // mise à jour des listes
                this.setItemsFullList();
                this.setItemsList();
                this.setOwnItemsFullList();
            }).catch((error: any) => {
                alert('error : ' + error);
            });

            // on désactive la barre de chargement
            this.setLoadingStatus('false');
        }
    }

    /**
     * Ouvre la modal du formulaire d'édition
     *
     * @protected
     * @return {void}
     */
    protected openModal(): void {
        this.setModalData(this.modalData);
        this.$bvModal.show('modalForm');
    }


    /**
     * Ouvre la sidebar
     *
     * @protected
     * @return {void}
     */
    protected openSidebar(): void {
        this.setModalData(this.modalData);
        this.$root.$emit('bv::toggle::collapse', 'sidebarForm');
    }

    /**
     * Permet de vérifier si il y a une date de sortie et une date de fin afin d'afficher aucune à la place si c'est vide.
     *
     * @protected
     * @return {void}
     */
    protected checkIfDate(): void {
        // vérifie si il y a "une date de sortie" sur l'item pour adapter l'affichage
        if (this.data.releaseDate) {
            this.showReleaseDate = this.commonFunc.formatDate(this.data.releaseDate, false);
        } else {
            this.showReleaseDate = 'Aucune';
        }

        // vérifie si il y a "une date de fin" sur l'item pour adapter l'affichage
        if (this.data.endDate) {
            this.showEndDate = this.commonFunc.formatDate(this.data.endDate, false);
        } else {
            this.showEndDate = 'Aucune';
        }
    }

    /**
     * On change la couleur de l'item en fonction de son statut.
     *
     * @protected
     * @return {object}
     */
    protected get prepareStyle(): object {
        const style = {
            background: '',
        };
        if (this.data.statut === 'Incomplète') {
            style.background = '#ff000030';
        } else if (this.data.statut === 'Complète' || this.data.statut === 'À jour') {
            style.background = '#00ff0030';
        }
        return style;
    }

    /**
     * On calcule le pourcentage de livre lu
     *
     * @protected
     * @return {number}
     */
    protected get percentRead(): number {
        if (this.data.tomes === undefined) {
            return 0;
        }

        // calcule le pourcentage de item lu
        return  Common.calculPercent(this.data.tomes.nbrTomeLu, this.data.tomes.nbrTome);
    }

    /**
     * On calcule la note en pourcentage
     *
     * @protected
     * @return {number}
     */
    protected get percentNote(): number {
        if (this.data.note !== 0) {
            return Common.calculPercent(this.data.note, 20);
        }
        return 0;
    }
}
