import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ItemListComponent from '@/components/Category/Item/ItemForList.vue';
import ItemTableComponent from '@/components/Category/Item/ItemForTable.vue';
import Pagination from '@/components/Category/List/Pagination/Pagination.vue';
import {CategoryFunctions} from '@/services/CategoryFunctions';
import {BaseScript} from '@/components/BaseScript';
import {ItemFieldsConfig} from '@/config/ItemFieldsConfig';
import Common from '@/services/Common';

// modules
const category = namespace('category');
const general = namespace('general');

@Component({
    name: 'itemsList',
    components: {
        ItemListComponent,
        ItemTableComponent,
        Pagination,
    },
})
export default class List extends BaseScript {
    private commonFunc: any = Common;
    private itemsList: any = []; // liste des items récupéré dans le store
    private nbrItemShown: number = 0; // Nombre d'item à afficher
    private defaultItemShown: number = 20; // Nombre par défaut d'item à afficher (sera utilisé pour nbrItemShown au chargement de la page)
    private tableMode: boolean = false; // Si l'affichage par défault en tableau est activé
    private itemTableFields: any = ItemFieldsConfig.tableItemsFields(); // Liste des colonnes pouvant être utilisé dans le tableau
    private tablePage: any = { // Utilisé pour la pagination du tableau
        page: 1,
        nbrItem: 25,
    };
    private isVisitor: boolean = Common.getIsVisitor(); // Savoir si on visite un autre profile
    private triList: any = { // Options pour le système de tri
        titreAsc: {
            label: 'Titre (A - Z)',
            value: 'title',
            sort: 'asc',
        },
        titreDesc: {
            label: 'Titre (Z - A)',
            value: 'title',
            sort: 'desc',
        },
        noteAsc: {
            label: 'Note (0 - 20)',
            value: 'note',
            sort: 'asc',
        },
        noteDesc: {
            label: 'Note (20 - 0)',
            value: 'note',
            sort: 'desc',
        },
    };
    private choiceTri: any = {}; // le choix du tri

    // actions
    @general.Action('setLoadingStatus') private readonly setLoadingStatus!: any;
    @category.Action('setCurrentCategoryFunc') private readonly setCurrentCategoryFunc!: any;

    // getters
    @general.Getter('getUserInfos') private readonly getUserInfos!: any;
    @category.Getter('getCollectionData') private readonly getCollectionData!: any;
    @category.Getter('getItemsList') private readonly getItemsList!: any;
    @category.Getter('getSearchData') private readonly getSearchData!: any;

    constructor() {
        super();
    }

    /**
     * @async
     * @private
     * @return {Promise<void>}
     */
    private async mounted(): Promise<void> {
        this.setLoadingStatus(true);
        this.scroll();

        // définition du tri par défaut
        this.choiceTri = this.triList.titreAsc;

        // permet d'éviter les erreurs si on a pas de token parsé
        if (Common.checkIsNotUndifined(this.getUserInfos.tokenParsed)) {
            // on récupére le mode d'affichage des items
            this.getTableMode();
        }

        // va directement à l'item si un est sélectionné dans l'url
        if (window.location.hash) {
            setTimeout(() => {
                window.location.replace(this.getCollectionData.rootLink + window.location.hash);
            }, 1000);
        }
    }

    /**
     * Récupére la configuration utilisateur définissant si oui ou non le tableau doit s'afficher par défaut
     *
     * @private
     * @return {void}
     */
    @Watch('getUserInfos')
    private getTableMode(): void {
        this.tableMode = this.getUserInfos.tokenParsed.itemTypeList ?? false;

    }

    /**
     * Si la liste des items change dans le store, on recharge la liste situé dans le composant
     *
     * @private
     * @return {void}
     */
    @Watch('getItemsList')
    private loadList(): void {
        this.itemsList = this.getItemsList;
        this.itemShownMoreThanList(this.defaultItemShown);
        this.setLoadingStatus(false);
    }

    /**
     * Si la liste des items change dans le store, on recharge la liste situé dans le composant
     *
     * @private
     * @return {void}
     */
    @Watch('getSearchData')
    private checkSearchData(): void {
        this.tablePage.page = 1;
    }

    /**
     * Permet de rajouter 8 items à afficher quand on touche le bas de la page.
     *
     * @private
     * @return {void}
     */
    private scroll(): void {
        window.onscroll = () => {
            const bottomOfWindow = Math.max(window.scrollY, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;

            if (bottomOfWindow) {
                this.addItem();
            }
        };
    }

    /**
     * Ajoute des items dans la liste
     *
     * @private
     * @return {void}
     */
    private addItem(): void {
        this.nbrItemShown += this.defaultItemShown;
        if (this.nbrItemShown > this.updateListeByFilter.length) {
            this.itemShownMoreThanList();
        }
    }

    /**
     * Permet de vérifier si le nombre d'item à afficher n'est pas plus grande la taille de liste d'items.
     * Si c'est le cas, le nombre d'item à montrer prend la valeur de la taille de la liste
     *
     * @private
     * @param {number} nbrItemShown - Nombre total d'item à afficher
     * @param {number} listeItem - La taille du tableau de la liste d'items à afficher
     * @return {void}
     */
    private itemShownMoreThanList(nbrItemShown: number = this.nbrItemShown, listeItem: number = this.updateListeByFilter.length): void {
        if (nbrItemShown > listeItem) {
            this.nbrItemShown = listeItem;
        } else {
            this.nbrItemShown = nbrItemShown;
        }
    }



    /**
     * Récupére la liste des items en fonction du filtre
     *
     * @private
     * @return {any[]}
     */
    private get updateListeByFilter(): any[] {
        // on filtre les items
        let list: any = CategoryFunctions.filterItem(this.getSearchData, this.itemsList, this.getCollectionData);

        // on applique le tri
        list = Common.sortObjectArray(list, this.choiceTri.value, this.choiceTri.sort);

        // si des filtres son sélectionnés
        if (Object.keys(this.getSearchData).length > 0) {
            this.itemShownMoreThanList(this.defaultItemShown, list.length);
        }
        return list;
    }

    /**
     * Récupére le nombre d'item par page pour le tableau.
     *
     * @private
     * @return {any[]}
     */
    private get updateListeForTable(): any[] {
        const startItem: number = this.tablePage.page * this.tablePage.nbrItem - this.tablePage.nbrItem;
        const endItem: number = this.tablePage.page * this.tablePage.nbrItem;
        return this.updateListeByFilter.slice(startItem, endItem);
    }
}
