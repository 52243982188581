import {Component, Prop} from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';

@Component({
    name: 'Pagination',
})
export default class Pagination extends BaseScript {
    @Prop(Object) private readonly tablePage!: any;
    @Prop(Number) private readonly itemLength!: any;

    /**
     * Récupére le nombre de page
     *
     * @return number
     */
    private get getPagination(): number {
        return Math.ceil(this.itemLength / this.tablePage.nbrItem);
    }

    /**
     * Permet de rajouté la class "item-pagination-selected" sur le numéro de page sélectionné
     *
     * @param page number
     * @return string
     */
    private selectedPagination(page: number): string {
        if (page === this.tablePage.page) {
            return 'item-pagination-selected';
        }
        return '';
    }

    /**
     * Va à la page suivante si il y a une page après
     *
     * @return void
     */
    private nextPage(): void {
        if (this.tablePage.page < this.getPagination) {
            this.tablePage.page += 1;
        }
    }

    /**
     * Va à la page précédente si il y a une page avant
     *
     * @return void
     */
    private previousPage() {
        if (this.tablePage.page > 1) {
            this.tablePage.page -= 1;
        }
    }
}
