
import {Component} from 'vue-property-decorator';
import ItemBaseScript from './ItemBaseScript';

@Component({
    name: 'itemForList',
})
export default class ItemForList extends ItemBaseScript {
    private get backgroundImage(): string {
        if (!this.data.image) {
            return '';
        }

        return 'background-image: url(\'' + this.data.image + '\');';
    }

    private get classActionButtons(): string {
        return this.isVisitor ? 'col-md-12 action-button' : 'col-md-6 action-button';
    }
}
