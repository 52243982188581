
import {Component, Prop} from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';
import {namespace} from 'vuex-class';
import {formIconsList} from '@/assets/img/formIcons';
import Common from '@/services/Common';

const category = namespace('category');

@Component({
    name: 'statutEtatBlock',
})
export default class StatutEtatBlock extends BaseScript {
    @Prop(Object) private readonly data!: any;

    private iconsList: any = formIconsList(); // liste des icônes
    private commonFunc: any = Common;

    // getters
    @category.Getter('getCollectionData') private readonly getCollectionData!: any;

    constructor() {
        super();
    }
}
