import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Common from '@/services/Common';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import '@/services/VeeValidateRules';
import {BaseScript} from '@/components/BaseScript';
import {BaseModel} from '@/models/BaseModel';
import {CategoryFunctions} from '@/services/CategoryFunctions';
import {categoryTypes} from '@/config/CategoryType';
import {config} from '@/config/CategoryConfig';
import TomeItem from '@/models/Livres/Composants/TomeItem';
import draggable from 'vuedraggable';
import {Crud} from '@/services/Crud';

// modules
const category = namespace('category');
const general = namespace('general');

@Component({
    name: 'Form',
    components: {
        ValidationProvider,
        ValidationObserver,
        draggable,
    },
})
export default class Form extends BaseScript {
    private item: any = {}; // item utilisé pour le formulaire

    /*
     * PARAMETRES POUR LE FINDER
     */
    private searchItem: string = ''; // champs de recherche de l'auto finder
    private finderItemsList: object[] = []; // Liste des items de l'auto finder
    private finderNoOptionMsg: string = this.commonStrings.selectNoOptionMinChar(3); // msg no option pour auto finder
    private finderSaveSearchString: string = ''; // permet de save les 3 premiers caractères utilisés à la recherche.
    private finderSelectedItem: any = null; // élément sélectionné dans le finder.

    private commonFunc: any = Common; // les fonctions communes à toute l'appli
    private file: any = null; // fichier de l'image de couverture
    private oldImgLink: string = ''; // ancienne image selectionnée
    private successAlert: any = {
        show: false,
        msg: '',
    }; // alert dans le cas d'un ajout ou d'une mise à jour avec succès
    private categoryConfig: any = CategoryFunctions.getAllConfig(); // liste des catégories utilisé pour la sélection de l'oeuvre original
    private itemsForOeuvre: any[] = []; // Liste des items à afficher dans le choix de l'oeuvre original
    private tmpOeuvre: any = null; // oeuvre sélectionné dans la liste.
    private config: any = config;
    private tomesAddForm: any = {
        title: 'Tome',
        withNbr: true,
        tomes: 1,
        lu: 0,
        edition: 'Normal',
        defaut: [],
    };
    private commentLength: number = 1000;

    // props
    @Prop(Object) private readonly getData!: object; // données d'un item sélectionné dans la liste

    // actions
    @general.Action('setLoadingStatus') private readonly setLoadingStatus!: any;
    @general.Action('addChangeLog') private readonly addChangeLog!: any;
    @general.Action('deleteSelectedImg') private readonly deleteSelectedImg!: any;
    @general.Action('setModalData') private setModalData!: any;
    @category.Action('getItem') private readonly getItem!: any;
    @category.Action('createItem') private readonly createItem!: any;
    @category.Action('updateItem') private readonly updateItemFunc!: any;
    @category.Action('setItemsList') private readonly setItemsList!: any;
    @category.Action('setOwnItemsFullList') private readonly setOwnItemsFullList!: any;
    @category.Action('setItemsFullList') private readonly setItemsFullList!: any;


    // getters
    @category.Getter('getCollectionData') private readonly getCollectionData!: any;
    @category.Getter('getItemsFullList') private readonly getItemsFullListFunc!: any;
    @general.Getter('getModalData') private readonly getModalData!: any;

    constructor() {
        super();
    }

    /**
     * @async
     * @private
     * @return {Promise<void>}
     */
    private async mounted(): Promise<void> {
        // initialisation du formulaire
        this.item = this.initForm();

        // Dans le cas où on a sélectionné une oeuvre dans notre collection, on initialise la liste des items
        await this.getItemsForOeuvre();

        // on indique l'oeuvre sélectionné si le champs existe, que "inCollection" est sur true et que l'on ait bien un titre
        if (Common.checkIsNotUndifined(this.item.oeuvre) && this.item.oeuvre.inCollection === true && Common.checkIsNotUndifined(this.item.oeuvre.title)) {
            this.tmpOeuvre = { title: this.item.oeuvre.title };
        }
    }

    /**
     * Permet de récupérer la liste des items. Nécessite 3 caractère minimum.
     *
     * @async
     * @private
     * @return { Promise<void> }
     */
    @Watch('searchItem')
    private async initAutoFinder(): Promise<void> {
        // on vérifie que l'on ait bien au moins les 3 premiers caractères, sinon on affiche le message d'erreur.
        if (this.searchItem.length < 3) {
            this.finderItemsList = [];
            this.finderNoOptionMsg = this.commonStrings.selectNoOptionMinChar(3);
            return;
        }

        // on récupére les 3 premiers caractère de l'input
        const tmpSearch: string = this.searchItem.slice(0, 3);

        // puis on vérifie si il sont différent de ceux utilisés lors de la précédente requête
        if (tmpSearch === this.finderSaveSearchString) {
            return;
        }

        // on récupére les 3 premiers caractères pour les sauvegarder
        this.finderSaveSearchString = tmpSearch;

        this.finderNoOptionMsg = this.commonStrings.selectNoOption;

        const crud = new Crud(process.env.VUE_APP_API_MODELS_PATH);
        await crud.getFullList(
            {
                title: {
                    $exists: true,
                    $regex: '(?i)' + this.finderSaveSearchString,
                },
                category: this.getCollectionData.category,
            },
            ['title'],
        ).then((data: any) => {
            this.finderItemsList = data;
        });
    }

    /**
     * Quand on obtient de nouvelles données, on les charges dans le formulaire
     *
     * @private
     * @return {void}
     */
    @Watch('getData')
    private switchForm(): void {
        // on initialise item si on édite une fiche
        this.item = this.initForm();

        // on reset les messages d'erreurs
        // @ts-ignore
        this.$refs.form.reset();

        // on reset le success message
        this.successAlert.show = false;
        this.successAlert.msg = '';
    }

    /**
     * Permet de récupérer la liste d'item en fonction de la catégorie choisi
     * pour les items
     *
     * @private
     * @return {void}
     */
    @Watch('item.oeuvre.category', {deep: true})
    private getItemsForOeuvre(): void {

        // on vérifie que l'on ait bien le champ oeuvre, que inCollection soit sur true et que l'on ait bien une catégorie de sélectionné
        if (Common.checkIsNotUndifined(this.item.oeuvre) && this.item.oeuvre.inCollection === true &&
            this.item.oeuvre.category !== '') {
            // on reset tmpOeuvre pour éviter les conflit dans la nouvelle liste
            this.tmpOeuvre = {};

            // on récupére la liste
            const categoryFunction = new CategoryFunctions(this.item.oeuvre.category);
            CategoryFunctions.getCrud().getListByUser(
                {
                    title: {$exists: true},
                    category: categoryFunction.data.category,
                },
                [{title: 'asc'}],
                null,
                Common.getCurrentUserId(),
            ).then((items: any) => this.itemsForOeuvre = items);
        }
    }

    /**
     * On récupére le titre de l'objet de l'oeuvre sélectionné pour l'assigner à oeuvre.title
     *
     * @private
     * @return {void}
     */
    @Watch('tmpOeuvre')
    private assignOeuvreTitle(): void {
        if (Common.checkIsNotUndifined(this.tmpOeuvre.title)) {
            this.item.oeuvre.title = this.tmpOeuvre.title;
        }
    }

    /**
     * Initialise le formulaire, et vérifie si c'est une création ou une édition d'item
     *
     * @private
     * @return {BaseModel}
     */
    private initForm(): BaseModel {
        let tmpData = {};

        // on vérifie si c'est la création d'une nouvelle fiche ou l'édition
        if (Common.checkIsNotUndifined(this.getData)) {
            tmpData = Common.unlinkTwoObjects(this.getData);

            // après la création d'une fiche, cela met à jour getData dans une instance de class.
            // Cela ajoute donc des _ aux proprietes que l'on doit supprimer
            if (this.getData.constructor.name === new this.getCollectionData.className().constructor.name) {
                tmpData = Common.formatObjectToNoUnderscore(tmpData);
            }
        }

        const item = new this.getCollectionData.className(tmpData);

        this.oldImgLink = item.image;
        return item;
    }

    /**
     * permet de récupérer la fiche sélectionné dans l'autofinder pour la copier dans cette fiche
     *
     * @private
     * @return {void}
     */
    @Watch('finderSelectedItem')
    private addInfosFromDbSelected(): void {
        if (!this.getData && this.finderSelectedItem !== null) {
            this.item = new this.getCollectionData.className(this.finderSelectedItem, true);
            this.finderSelectedItem = null;
        }
    }

    /**
     * Permet d'importer le fichier d'une image sélectionnée
     *
     * @async
     * @private
     * @return {Promise<void>}
     */
    private async importImage(): Promise<void> {
        // check si on a un fichier à charger
        if (this.file !== null) {
            // on réinitialise le lien
            this.item.image = '';

            // remplacement des espaces dans le titre et de "'" par "-"
            const tmpTitle: string = this.item.title.replace(/\s+/g, '-').replace('\'', '-').replace('/', '-');

            const prepPostData: any = JSON.stringify({
                accessToken: Vue.prototype.$keycloak.token,
                title: tmpTitle,
                action: 'create',
            });

            const formData: FormData = new FormData();
            formData.append('file', this.file);
            formData.append('data', prepPostData);

            const crud = CategoryFunctions.getCrud();

            // On ajoute l'image sur le serveur via l'api
            await crud.importImage(formData).then((data: any) => {
                if (typeof data.data === 'string') {
                    this.item.image = process.env.VUE_APP_URL_IMG + data.data;
                }
            }).catch(() => {
                alert('Une erreur s\'est produite lors de l\'upload du fichier !');
            });
        }
    }

    /**
     * Crée un nouvel item dans la bdd
     *
     * @private
     * @return {void}
     */
    private addItem(): void {
        // vérification des champs
        // @ts-ignore
        this.$refs.form.validate().then(async (success: any) => {
            if (success) {
                // affiche la barre de chargement
                this.setLoadingStatus('true');

                // importe le fichier d'une image si il y en a une
                await this.importImage();

                // mise à jour des défauts dans le cas des livres
                await this.refreshLivresDefaut();

                // récupére les données au format de la base de données
                const data = Common.formatObjectToNoUnderscore(this.item);

                this.createItem(data)
                    .then(async () => {
                        await this.addChangeLog({
                            uid: data.uid,
                            title: data.title,
                            category: this.getCollectionData.category,
                            type: this.getCollectionData.type,
                            date: data.updated_at,
                            statut: 'create',
                        });

                        // on rafraichi les données du site avec les modifications
                        this.refreshAllData();

                        await this.setModalData({
                            title: 'Editer ' + this.getCollectionData.phrases.leSingulier + ' : ' + data.title,
                            formName: 'ItemForm',
                            formIconName: 'edit',
                            docData: this.item,
                        });

                        // affichage du message success
                        this.successAlert.msg = 'La fiche de ' + data.title + ' a bien été crée !';
                        this.successAlert.show = true;
                    }).catch((err: any) => {
                    // on cache la barre de chargement
                    this.setLoadingStatus('false');
                    alert('error : ' + err);
                });
            }
        });
    }

    /**
     * Mise à jour d'un item dans la bdd
     *
     * @private
     * @return {void}
     */
    private updateItem(): void {
        // vérification des champs
        // @ts-ignore
        this.$refs.form.validate().then(async (success: any) => {
            if (success) {
                // affiche la barre de chargement
                this.setLoadingStatus('true');

                // Si l'ancien lien est différent du nouveau, on vérifie si on peut supprimer l'image
                if (this.item.image !== this.oldImgLink) {
                    await this.deleteSelectedImg(this.oldImgLink);
                }

                // importe le fichier d'une image si il y en a une
                await this.importImage();

                // mise à jour des défauts dans le cas des livres
                await this.refreshLivresDefaut();

                // récupére les données au format de la base de données
                const data = Common.formatObjectToNoUnderscore(this.item);

                // mise à jour de la date d'upload
                data.updated_at = Date.now();

                this.updateItemFunc(data)
                    .then(async () => {
                        // ajout dans change log
                        await this.addChangeLog({
                            uid: data.uid,
                            title: data.title,
                            category: this.getCollectionData.category,
                            type: this.getCollectionData.type,
                            date: data.updated_at,
                            statut: 'update',
                        });

                        // changement de l'en-tête du modal
                        const modalData = this.getModalData;
                        modalData.title = 'Editer ' + this.getCollectionData.phrases.leSingulier + ' : ' + data.title;

                        this.setModalData(modalData);

                        // on raffraichi les données du site avec les modifications
                        this.refreshAllData();

                        // affichage du message success
                        this.successAlert.msg = 'La fiche de ' + data.title + ' a bien été mise à jour !';
                        this.successAlert.show = true;
                    }).catch((err: any) => {

                    // on cache la barre de chargement
                    this.setLoadingStatus('false');

                    alert('error : ' + err);
                });
            }
        });
    }

    /**
     * On rafraichi toutes les données du site lors de l'ajout ou de l'édition d'un item
     *
     * @private
     * @return {void}
     */
    private refreshAllData(): void {
        this.file = null;

        // mise à jour des listes
        this.setItemsList();
        this.setOwnItemsFullList();
        this.setItemsFullList();
    }

    /**
     * affiche l'input client si l'état est sur prêt ou vendu
     *
     * @return boolean
     */
    private checkClient(): boolean {
        // sinon on reset le nom du client
        if (this.item.etat === 'Prêté' || this.item.etat === 'Vendu') {
            return true;
        } else {
            this.item.client = '';
            return false;
        }
    }

    /**
     * Génére un nouveau lien manga news en fonction du titre
     *
     * @private
     * @return {void}
     */
    private mangaNewsLink(): void {
        // On génére le lien item news en fonction du titre
        const mangaNewsLink = 'https://www.manga-news.com/index.php/';
        let mangaNewsLinkTitle = this.item.title.replace(/ /g, '-');
        mangaNewsLinkTitle = mangaNewsLinkTitle.replace('\'s', '');
        mangaNewsLinkTitle = mangaNewsLinkTitle.replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g, '');
        if (this.item.langue === 'Français') {
            this.item.externalLink = mangaNewsLink + 'serie/' + mangaNewsLinkTitle;
        } else {
            this.item.externalLink = mangaNewsLink + 'serie-vo/' + mangaNewsLinkTitle + '-vo';
        }
    }

    /**
     * Ajoute des tomes à l'item avec les éléments saisis dans le formulaire d'ajout de tomes
     *
     * @private
     * @return {void}
     */
    private addTomes(): void {
        // récupération de l'ancien nombre de tome pour commencer l'itération à partir de ce nombre
        const oldNbrTome: number = this.item.tomes.nbrTome;

        // ajout des tomes
        for (let i = oldNbrTome + 1; i <= oldNbrTome + this.tomesAddForm.tomes; i++) {
            // si aucune édition sélectionné, on attribut la valeur Noraml par défaut
            const edition = this.tomesAddForm.edition ?? 'Normal';

            // récupération du titre
            let title: string = this.tomesAddForm.title;

            // si on souhaite utiliser l'itération sur le titre
            if (this.tomesAddForm.withNbr) {
                title += ' ' + i;
            }

            // on crée le tome
            const tmpItem: TomeItem = new TomeItem();
            tmpItem.title = title;
            tmpItem.edition = edition;
            tmpItem.defaut = this.tomesAddForm.defaut;

            // on met le tome au status lu si besoin en est et on incrémente le nombre de tome lu total
            if (i <= this.tomesAddForm.lu + oldNbrTome) {
                this.item.tomes.nbrTomeLu++;
                tmpItem.lu = true;
            }

            // on incrémente le nombre de tome total et on ajoute le tome à notre tableau
            this.item.tomes.nbrTome++;
            this.item.tomes.items.push(tmpItem);
        }
    }

    /**
     * Permet de supprimer un tome de la liste
     *
     * @private
     * @param {string|number} idx
     * @return {void}
     */
    private deleteTome(idx: string|number): void {
        const tome = this.item.tomes.items[idx];

        // on retire 1 tome au total des tomes ainsi qu'au nombre de tomes lu total si il était à lu
        this.item.tomes.nbrTome--;
        if (tome._lu) {
            this.item.tomes.nbrTomeLu--;
        }

        // on supprime le tome
        this.item.tomes.items.splice(idx, 1);
    }

    /**
     * Ajoute/enlève 1 au nombre de total vu quand on check ou uncheck une checkbox lu
     *
     * @private
     * @param {string|number} idx
     * @return {void}
     */
    private checkTomeLu(idx: string|number): void {
        const tome = this.item.tomes.items[idx];

        if (tome._lu) {
            this.item.tomes.nbrTomeLu++;
            return;
        }

        this.item.tomes.nbrTomeLu--;
    }

    /**
     * Refresh la liste globale des défauts d'un livre.
     *
     * @private
     * @return {void}
     */
    private refreshLivresDefaut(): void {
        if (!this.isTypeLivres) {
            return;
        }
        this.item.defaut = [];

        for (const tome of this.item.tomes.items) {
            if (this.commonFunc.checkIsNotUndifined(tome._defaut) && Array.isArray(tome._defaut)) {
                this.item.defaut = [...new Set([...this.item.defaut, ...tome._defaut])];
            }
        }
    }

    /**
     * Permet de vérifier que le nombre de joueur max est toujours supérieur ou égal au nombre de joueur minimum
     *
     * @private
     * @return {void}
     */
    private checkMaxPlayer(): void {
        if (this.item.maxPlayer < this.item.minPlayer) {
            this.item.maxPlayer = this.item.minPlayer;
        }
    }

    /**
     * L'item a pour type "Livres"
     *
     * @private
     */
    private get isTypeLivres(): boolean {
        if (this.commonFunc.checkIsNotUndifined(this.item.category)) {
            return this.categoryConfig[this.item.category].type === categoryTypes.LIVRES_TYPE;
        }
        return false;
    }
}
