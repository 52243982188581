import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {BaseModel} from '@/models/BaseModel';
import Common from '@/services/Common';
import ItemBaseScript from '@/components/Category/Item/ItemBaseScript';

// modules
const general = namespace('general');

@Component({
    name: 'SidebarItem',
})
export default class SidebarItem extends ItemBaseScript {

    protected data: any = new BaseModel({}, false, '');
    private initSidebar: boolean = false; // utilisé pour savoir si on a bien initiliasé la sidebar avec un premier item

    @general.Getter('getModalData') private readonly getModalData!: any;

    constructor() {
        super();
    }

    /**
     * Récupére les données
     *
     * @private
     * @return {void}
     */
    @Watch('getModalData', {deep: true})
    private getDataFromModal(): void {
        if (Common.checkIsNotUndifined(this.getModalData.docData)) {
            this.initSidebar = true;

            this.data = this.getModalData.docData;

            this.checkIfDate();
        }
    }

    /**
     * Tri les genres par ordre alphabétique
     *
     * @private
     * @return {array}
     */
    private get sortedGenre() {
        return this.data.genre.sort();
    }
}
