
    import {Component} from 'vue-property-decorator';
    import ItemBaseScript from './ItemBaseScript';
    import {ItemFieldsConfig} from '@/config/ItemFieldsConfig';

    @Component({
        name: 'itemForTable',
    })
    export default class ItemForTable extends ItemBaseScript {
        private itemTableFields: any = ItemFieldsConfig.tableItemsFields();

        constructor() {
            super();
        }

        /**
         * Si la donnée de la colonne est un tag, alors on ajoute cette class pour améliorer l'affichage de celui-ci
         *
         * @param tagType boolean
         * @return string
         */
        private getStatutEtatClass(tagType: boolean): string {
            if (tagType) {
                return 'statutEtatBlock';
            }
            return '';
        }
    }
