import { Component } from 'vue-property-decorator';
import ItemForm from '@/components/Category/Form/Form.vue';
import {formIconsList} from '@/assets/img/formIcons';
import {namespace} from 'vuex-class';
import {BaseScript} from '@/components/BaseScript';

// modules
const general = namespace('general');

@Component({
    name: 'ModalForm',
    components: {
        ItemForm,
    },
})
export default class ModalForm extends BaseScript {
    @general.Getter('getModalData') private readonly getModalData!: any;

    private iconsList: any;

    constructor() {
        super();
        this.iconsList = formIconsList();
    }

    /**
     * Récupére le nom du formulaire à afficher
     *
     * @private
     * @return {string}
     */
    private get getFormName() {
        return this.getModalData.formName;
    }
}
