import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {InfosCollection} from '@/services/InfosCollection';
import {BaseScript} from '@/components/BaseScript';
import {CategoryFunctions} from '@/services/CategoryFunctions';
import Common from '@/services/Common';

// modules
const category = namespace('category');

@Component({
    name: 'Infos',
})
export default class Infos extends BaseScript {
    private infosCollection: InfosCollection = new InfosCollection();
    private commonFunc: any = Common;

    // getters
    @category.Getter('getItemsList') private readonly getItemsList!: any;
    @category.Getter('getCollectionData') private readonly getCollectionData!: any;
    @category.Getter('getSearchData') private readonly getSearchData!: any;

    constructor() {
        super();
    }

    /**
     * Met à jour si la liste des items à changé
     *
     * @private
     * @return {void}
     */
    @Watch('getItemsList')
    private watchGetItemsList(): void {
        this.updateItemsList();
    }

    /**
     * Met à jour si les données du filtre ont changés
     *
     * @private
     * @return {void}
     */
    @Watch('getSearchData')
    private watchGetSearchData(): void {
        this.updateItemsList();
    }

    /**
     * Met à jour la liste des items
     *
     * @private
     * @return {void}
     */
    private updateItemsList(): void {
        // on filtre les items
        this.infosCollection.itemsList = CategoryFunctions.filterItem(this.getSearchData, this.getItemsList, this.getCollectionData);
    }
}
